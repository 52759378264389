import axios from 'axios';
import { reactive } from 'vue';
import { UtilsInterface, ValidateResponse, MitIdUrlPayload, Product, DynamicObject } from './interfaces';
const utils: UtilsInterface = reactive({
	apiurl: 'https://api.telecomx.dk', //'http://localhost:3000'
	token: '',
	constructMitIdUrl: constructMitIdUrl,
	validate: validate,
	customer: '',
	twoDigit: twoDigit,
	keepSession: keepSession,
	products: [],
	getProducts: getProducts,
	ratelimit: ratelimit,
	resellerName: '',
	mitIdRedirectURL: 'https://mobil.sundbynet.dk/validatecallback', //http://localhost:8080/validatecallback
	mitIdApplicationId: 'urn:my:application:identifier:687518', //urn:my:application:identifier:10683
	mitIdDomain: 'mobil.criipto.id' //sundbynet-test.criipto.id
});


function constructMitIdUrl ( payload: MitIdUrlPayload ) {
	const url = `${payload.base}/oauth2/authorize?scope=openid&client_id=${payload.client_id}&redirect_uri=${payload.redirect_uri}&response_type=code&response_mode=query&nonce=ecnon-358f5068-1da0-41a6-b40c-fee151c46d8a&state=${payload.state}&acr_values=urn:grn:authn:dk:mitid:substantial&login_hint=action:approve message:${btoa(payload.message)}`;
	return url;
}

async function validate() : Promise<ValidateResponse> {
	const {data} = await axios.get(`${utils.apiurl}/auth/validateToken`, {params: { token: utils.token }});
	if (!data.success) {
		// DEN ER FEJLED THROOOOW
		throw Error('CANNOT VALIDATE');
	}
	return data;
}

function twoDigit(num: number): number {
	return num < 10 ? parseInt(`0${num}`) : num;
}

async function getProducts(): Promise<Product[]> {
	try {
		const data: { products: Array<Product> } = await axios.get(`${utils.apiurl}/product?token=${utils.token}&customerProducts=true&type=MVNO_RATEPLAN&all=false&full=true&customer=${utils.customer}`).then(res => res.data);
		data.products.forEach(product => {
			product.price = product.price*1.25
		})
		utils.products = data.products.sort((a, b) => a.price-b.price );
		return utils.products;
	} catch(err) {
		utils.validate();
		utils.products = [];
		return [];
	}
}

async function keepSession() {
	try {
		await axios.get(`${utils.apiurl}/ping`, { params: { token: utils.token } });
		setTimeout(keepSession, 1000*60);
	} catch(err) {
		utils.token = '';
		utils.customer = '';
		sessionStorage.removeItem('token');
	}
}

const ratelimits: DynamicObject = {}

function ratelimit(id: string, run: () => void) {
	const hasRatelimit = ratelimits[id];
	if (hasRatelimit) {
		clearTimeout(ratelimits[id])
		ratelimits[id] = null;
	}
	ratelimits[id] = setTimeout(() => {
		run();
	}, 600);
}

export default utils;